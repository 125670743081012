import { Container } from 'react-bootstrap';
import './MainHeader.scss';

function MainHeader() {
    return (
        <header className="main-header">
            <h1 className='d-none'>Творческая эстафета ВЕСЁЛАЯ МАСЛЕНИЦА</h1>
            <Container className='main-container'>
                <div className='logo-block'>
                    <a className='logo-minpros-image logo-item' href='https://edu.gov.ru/'> </a>
                    <a className='logo-arsa-image logo-item' href='/'> </a>
                    <a className='logo-cms-image logo-item' href='https://cic-edu.ru/'> </a>
                </div>
                <div className='title-block'>
                    <div className='title-text1'>
                        весёлая
                    </div>
                    <div className='title-image title-block-image'>
                    </div>
                    <div className='title-text2'>
                        Творческая эстафета
                    </div>
                </div>
                <div className='right-block header-right-image'></div>
            </Container>
        </header >
    );
}

export default MainHeader;
